<template>
  <div v-if="!active">
    <v-switch v-model="computedSql.atemporal" :disabled="readOnly" hide-details label="Atemporal" />
    <v-text-field v-model="computedSql.realtime" class="pt-6" :disabled="readOnly" outlined hide-details label="Realtime" />
    <v-text-field v-if="computedSql.query" v-model="computedSql.query.collection" :readonly="readOnly" hide-details label="Collection" />
    <v-text-field v-if="computedSql.query" v-model="computedSql.query.database" :readonly="readOnly" hide-details label="Database" />

    <v-jsoneditor v-if="!active" v-model="computedSql.query.body" :plus="false" :options="options" />

    <div v-if="computedSql.atemporal === false" class="mt-10">
      <v-text-field v-if="computedSql.query" v-model="computedSql.query.date_field" :readonly="readOnly" label="date_field" outlined />
      <v-text-field v-if="computedSql.query" v-model="computedSql.query.date_filter_format" :readonly="readOnly" label="date_filter_format" outlined />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sql: Object,
    show: Boolean,
    editable: Boolean
  },
  computed: {
    computedSql: {
      get() {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sql.query = {
          body: this.sql?.query?.body || '',
          collection: this.sql?.query?.collection,
          database: this.sql?.query?.database,
          date_field: this.sql?.query?.date_field,
          date_filter_format: this.sql?.query?.date_filter_format,
          fields_output_order: this.sql?.query?.fields_output_order
        }
        return this.sql
      },
      set(sql) {
        this.$emit('update:sql', sql)
      }
    },
    active: {
      get() {
        return this.show
      }
    },
    readOnly() {
      if (this.editable === undefined) return true
      return !this.editable
    }
  }
}
</script>
